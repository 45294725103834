export default function authHeader() {
  const user = localStorage.getItem("user_name");
  const accessToken = localStorage.getItem("access_token");

  if (user && accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return {};
  }
}
