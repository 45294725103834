import { combineReducers } from "redux";
import authReducer from "./auth/AuthReducer";
import tabMasterReducer from "./tabs/TabMasterReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  tabMasterReducer: tabMasterReducer,
});

export default rootReducer;
