import React from "react";
import loader from "../assets/images/loader.png";

const Loader = () => {
  return (
    <div className="preloader">
      <div
        className="preloader__image"
        style={{ backgroundImage: `url(${loader})` }}
      />
    </div>
  );
};

export default Loader;
