import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPaperPlane,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "../commonComponents/utils";
import logoDark from "../assets/images/logo-dark.png";
import footerGallary from "../assets/images/gallery/footer-widget-gallery-img-1.jpg";
import handLeft from "../assets/images/shapes/footer-shape-hand-1-1.png";
import handRight from "../assets/images/shapes/footer-shape-hand-1-2.png";
import footerBottom from "../assets/images/backgrounds/footer-bottom-bg-1-2.png";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const currentYear = new Date().getFullYear();

  const handleScroll = () => {
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollY = window.scrollY;
    const percentage = (scrollY / scrollHeight) * 100;
    // You can adjust the scroll threshold as needed
    const scrollThreshold = 200;

    setScrollPercentage(percentage);
    setIsVisible(scrollY > scrollThreshold);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // The empty dependency array ensures the effect runs only once on mount

  return (
    <div className={"relative overflow-hidden bg-cleenheartsWhite2"}>
      {/* menu items footer */}
      <div className={"relative"}>
        <div className={"py-16 max-w-6xl mx-auto"}>
          <div className={"flex flex-wrap justify-between"}>
            <div className={"px-4 min-[1200px]:w-[25%] w-full"}>
              <div className={"mb-7"}>
                <Link to={"/"}>
                  <img
                    src={logoDark}
                    width={"159"}
                    alt={"Akarma Logo"}
                    className={"mb-10"}
                  />
                </Link>
                <p className={"capitalize text-cleenheartsText leading-[1.75]"}>
                  A state-of-the-art technology platform primarily for the
                  benefit social entrepreneurs in India
                </p>
              </div>

              <a
                href={
                  "https://www.google.com/maps/@23.506657,90.3443647,7z?entry=ttu"
                }
                className={
                  "flex items-center text-sm font-medium text-cleenheartsPurple"
                }
              >
                <span className={"mr-3"}> View Map </span>
                <FontAwesomeIcon icon={faPaperPlane} size={"1x"} />
              </a>
            </div>

            <div className={"px-4"}>
              <h2 className={"footer-widget__title"}> Get in touch! </h2>

              <ul>
                <li className={"flex items-center text-cleenheartsText"}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className={"text-cleenheartsPurple mr-2"}
                    size={"1x"}
                  />
                  <address className={"mb-0"}>
                    Ahmedabad, Gujarat, India
                  </address>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-3"}>
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    className={"text-cleenheartsPurple mr-2"}
                    size={"1x"}
                  />
                  <div className={"relative"}>
                    <a
                      href={"tel:(406)555-0120"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer"
                      }
                    >
                      +91 999 888 0120
                    </a>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-3"}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={"text-cleenheartsPurple mr-2"}
                    size={"1x"}
                  />
                  <div className={"relative"}>
                    <a
                      href={"mailto:contact@akarma.in"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer"
                      }
                    >
                      contact@akarma.in
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className={"px-4"}>
              <h2 className={"footer-widget__title__Links"}> Quick Links </h2>

              <ul>
                <li className={"flex items-center text-cleenheartsText"}>
                  <div className={"relative"}>
                    <Link
                      to={"/about"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer"
                      }
                    >
                      About Akarma
                    </Link>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/registration"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer"
                      }
                    >
                      Registration
                    </Link>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/smartSearch"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer"
                      }
                    >
                      NGO Smart Search
                    </Link>
                  </div>
                </li>

                <li className={"flex items-center text-cleenheartsText mt-2"}>
                  <div className={"relative"}>
                    <Link
                      to={"/contact-us"}
                      className={
                        "hover:text-cleenheartsBase hover-underline-animation-footer"
                      }
                    >
                      Contact Us
                    </Link>
                  </div>
                </li>
              </ul>
            </div>

            <div className={"px-4"}>
              <h2 className={"footer-widget__title__Gallary"}> Our Gallery </h2>

              <div className={"grid grid-flow-row grid-cols-3 gap-2.5"}>
                <div className={"relative"}>
                  <Link to={"/"} className={"footer-widget__gallery__link"}>
                    <img
                      src={footerGallary}
                      alt={"footer-widget-gallery"}
                      className={"rounded-[10px]"}
                    />
                    <span
                      className={"footer-widget__gallery__icon icon-plus"}
                    />
                  </Link>
                </div>

                <div className={"relative"}>
                  <Link to={"/"} className={"footer-widget__gallery__link"}>
                    <img
                      src={footerGallary}
                      alt={"footer-widget-gallery"}
                      className={"rounded-[10px]"}
                    />
                    <span
                      className={"footer-widget__gallery__icon icon-plus"}
                    />
                  </Link>
                </div>

                <div className={"relative"}>
                  <Link to={"/"} className={"footer-widget__gallery__link"}>
                    <img
                      src={footerGallary}
                      alt={"footer-widget-gallery"}
                      className={"rounded-[10px]"}
                    />
                    <span
                      className={"footer-widget__gallery__icon icon-plus"}
                    />
                  </Link>
                </div>

                <div className={"relative"}>
                  <Link to={"/"} className={"footer-widget__gallery__link"}>
                    <img
                      src={footerGallary}
                      alt={"footer-widget-gallery"}
                      className={"rounded-[10px]"}
                    />
                    <span
                      className={"footer-widget__gallery__icon icon-plus"}
                    />
                  </Link>
                </div>

                <div className={"relative"}>
                  <Link to={"/"} className={"footer-widget__gallery__link"}>
                    <img
                      src={footerGallary}
                      alt={"footer-widget-gallery"}
                      className={"rounded-[10px]"}
                    />
                    <span
                      className={"footer-widget__gallery__icon icon-plus"}
                    />
                  </Link>
                </div>

                <div className={"relative z-2"}>
                  <Link to={"/"} className={"footer-widget__gallery__link"}>
                    <img
                      src={footerGallary}
                      alt={"footer-widget-gallery"}
                      className={"rounded-[10px]"}
                    />
                    <span
                      className={"footer-widget__gallery__icon icon-plus"}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img
              src={handLeft}
              alt={"hand"}
              className={"main-footer__top__shape-one"}
            />
            <img
              src={handRight}
              alt={"hand"}
              className={"main-footer__top__shape-two"}
            />
          </div>
        </div>
      </div>
      {/* Copy rights footer */}
      <div className={"relative py-6 bg-cleenheartsSecondary"}>
        <div
          className={
            "absolute left-0 bottom-0 w-full h-full mix-blend-multiply"
          }
          style={{ backgroundImage: `url(${footerBottom})` }}
        />
        <div className={"text-center"}>
          <p className={"text-sm font-semibold text-cleenheartsBase"}>
            &copy; Copyright {currentYear} Akarma All Rights Reserved.
          </p>
        </div>
      </div>
      {/* Back to top */}
      <div
        onClick={scrollToTop}
        className={classNames(
          isVisible ? "opacity-100 visible" : "opacity-0 hidden",
          "flex items-center w-auto h-[35px] bg-transparent fixed bottom-[60px] -right-3 z-[99] text-center cursor-pointer -rotate-90 transition-all ease-in"
        )}
      >
        <span
          className={"text-xs uppercase text-cleenheartsBase font-bold mr-2"}
        >
          back top
        </span>
        <span
          className={
            "inline-block w-[30px] h-1 bg-cleenheartsBase relative overflow-hidden"
          }
        >
          <span
            className={
              "absolute left-0 top-0 w-full h-full bg-cleenheartsSecondary"
            }
            style={{ width: `${scrollPercentage}%` }}
          />
        </span>
      </div>
    </div>
  );
};

export default Footer;
