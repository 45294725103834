import React, { useState } from "react";
import { CTabContent, CTabPane } from "@coreui/react";
import SocialContact from "../home/socialContact";
import Header from "../home/Header";
import Footer from "../home/Footer";
import TabMaster from "./TabMaster";
import SummaryTab from "./SummaryTab";
import DemographicsTab from "./DemographicsTab";
import AreaOfWorkTab from "./AreaOfWorkTab";
import Trustees_Members_DirectorsTabs from "./Trustees_Members_DirectorsTabs";
import GovernmentSupportTab from "./GovernmentSupportTab";
import Loader from "../commonComponents/Loader";

const Details = () => {
  const [loading, setLoading] = useState(false);
  const [tabName, setTabName] = useState("");
  const [selectedTabName, setSelectedTabName] = useState("Summary");

  const handleLoad = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <div onLoad={handleLoad}>
      {/* preloader */}
      {loading && <Loader />}

      <div className={"my-0 mx-auto w-full min-w-[300px] overflow-hidden"}>
        <SocialContact />
        <Header />

        <div className={"py-5"}>
          <TabMaster
            setTabName={setTabName}
            selectedTabName={selectedTabName}
            setSelectedTabName={setSelectedTabName}
          />

          <div className={""}>
            <div> {tabName === "summary" && <SummaryTab />} </div>
            <div> {tabName === "demographics" && <DemographicsTab />} </div>
            <div>
              {tabName === "area-of-work" && <AreaOfWorkTab />}
            </div>
            <div
            >
              {tabName === "trustees-members-directors" && (
                <Trustees_Members_DirectorsTabs />
              )}
            </div>
            <div
            >
              {tabName === "government-support" && <GovernmentSupportTab />}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Details;
