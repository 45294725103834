import React from "react";

const SmartSearch = () => {
  return (
    <>
      Smart Search
    </>
  );
};

export default SmartSearch;
