import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const SocialContact = () => {
  return (
    <div
      className={
        "md:flex hidden items-center justify-between bg-cleenheartsBase py-[22px] px-4"
      }
    >
      <ul className={"flex items-center"}>
        <li className={"flex items-center"}>
          <FontAwesomeIcon
            icon={faPaperPlane}
            size={"lg"}
            style={{ color: "#f6d469" }}
            className={"mr-4"}
          />
          <div className={"relative"}>
            <a
              href={"mailto:contact@akarma.in"}
              className={
                "hover-underline-animation text-GlossyGrape hover:text-cleenheartsSecondary"
              }
            >
              contact@akarma.in
            </a>
          </div>
        </li>
        {/* <li className={"flex items-center ml-8"}>
          <FontAwesomeIcon
            icon={faLocationDot}
            size={"lg"}
            style={{ color: "#f6d469" }}
            className={"mr-4"}
          />
          <p className={"text-GlossyGrape"}> Ahmedabad Gujarat India </p>
        </li> */}
      </ul>

      {/* <div className={"flex items-center"}>
        <a href={"https://facebook.com"} className={"mr-3"}>
          <FontAwesomeIcon
            icon={faFacebookF}
            size={"lg"}
            className={"text-white hover:!text-cleenheartsSecondary"}
          />
          <span className={"sr-only"}> Facebook </span>
        </a>
        <div className={"border-r border-r-Charcoal h-4 w-0.5"} />

        <a href={"https://twitter.com"} className={"mx-3"}>
          <FontAwesomeIcon
            icon={faTwitter}
            size={"lg"}
            className={"text-white hover:!text-cleenheartsSecondary"}
          />
          <span className={"sr-only"}> Twitter </span>
        </a>
        <div className={"border-r border-r-Charcoal h-4 w-0.5"} />

        <a href={"https://linkedin.com"} aria-hidden="true" className={"mx-3"}>
          <FontAwesomeIcon
            icon={faLinkedinIn}
            size={"lg"}
            className={"text-white hover:!text-cleenheartsSecondary"}
          />
          <span className={"sr-only"}> Linkedin </span>
        </a>
        <div className={"border-r border-r-Charcoal h-4 w-0.5"} />

        <a href={"https://youtube.com"} aria-hidden="true" className={"ml-3"}>
          <FontAwesomeIcon
            icon={faYoutube}
            size={"lg"}
            className={"text-white hover:!text-cleenheartsSecondary"}
          />
          <span className={"sr-only"}> Youtube </span>
        </a>
      </div> */}
    </div>
  );
};

export default SocialContact;
