import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabMasterAction } from "../../redux/action/tabs/TabMasterAction";
import classNames from "../commonComponents/utils";

const TabMaster = ({ setTabName, selectedTabName, setSelectedTabName }) => {
  const [tabList, setTabList] = useState([]);

  const dispatch = useDispatch();
  const tabMaster = useSelector((state) => state.tabMasterReducer.data);

  useEffect(() => {
    dispatch(TabMasterAction());
  }, [dispatch]);

  useEffect(() => {
    console.log("tabMaster", tabMaster);
    setTabList(tabMaster);
  }, [tabMaster]);
  
  const tabs = [
    {
      tab_id: 1,
      display_name: "Summary",
      slug: "summary",
      tab_for: "ngo_detail_page",
      display_order: 1,
    },
    {
      tab_id: 2,
      display_name: "Demographics",
      slug: "demographics",
      tab_for: "ngo_detail_page",
      display_order: 2,
    },
    {
      tab_id: 3,
      display_name: "Area of work",
      slug: "area-of-work",
      tab_for: "ngo_detail_page",
      display_order: 3,
    },
    {
      tab_id: 4,
      display_name: "Trustees / Members / Directors",
      slug: "trustees-members-directors",
      tab_for: "ngo_detail_page",
      display_order: 4,
    },
    {
      tab_id: 5,
      display_name: "Government Support",
      slug: "government-support",
      tab_for: "ngo_detail_page",
      display_order: 5,
    },
  ];

  const handleTabClick = (tabName) => {
    setTabName(tabName);
    if (tabName === "summary") {
      setSelectedTabName("Summary");
    } else if (tabName === "demographics") {
      setSelectedTabName("Demographics");
    } else if (tabName === "area-of-work") {
      setSelectedTabName("Area of work");
    } else if (tabName === "trustees-members-directors") {
      setSelectedTabName("Trustees / Members / Directors");
    } else if (tabName === "government-support") {
      setSelectedTabName("Government Support");
    }
  };

  return (
    <div className={"overflow-y-scroll flex items-center"}>
      {tabs.map((tab) => (
        <div
          key={tab.tab_id}
          className={"mr-3 cursor-pointer"}
          onClick={() => {
            handleTabClick(tab.slug);
          }}
        >
          <h5
            data-tab={tab.slug}
            className={classNames(
              selectedTabName === tab.display_name ? "text-4xl" : "",
              "text-lg"
            )}
          >
            {tab.display_name}
          </h5>
        </div>
      ))}
    </div>
  );
};

export default TabMaster;
