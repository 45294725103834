import { TAB_LIST_REQUEST, TAB_LIST_SUCCESS, TAB_LIST_FAILURE } from "../types";
import axiosInstance from "../../services/axiosInstance";
import authHeader from "../../services/auth-header";

export const TabMasterAction = () => {
  return (dispatch) => {
    dispatch({
      type: TAB_LIST_REQUEST,
    });
    axiosInstance
    .post(`v1/get_tabs/`, "", {
      headers: authHeader(),
    })
      .then((response) => {
        dispatch({
          type: TAB_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TAB_LIST_FAILURE,
          payload: error.message,
        });
      });
  };
};
