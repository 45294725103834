export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const RECREATE_ACCESS_TOKEN = "RECREATE_ACCESS_TOKEN";
export const RECREATE_ACCESS_TOKEN_SUCCESS = "RECREATE_ACCESS_TOKEN_SUCCESS";
export const RECREATE_ACCESS_TOKEN_FAILURE = "RECREATE_ACCESS_TOKEN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const TAB_LIST_REQUEST = "TAB_LIST_REQUEST";
export const TAB_LIST_SUCCESS = "TAB_LIST_SUCCESS";
export const TAB_LIST_FAILURE = "TAB_LIST_FAILURE";
