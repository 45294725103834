import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateGuard";
import Details from "../components/details";

const Home = lazy(() => import("../components/home"));
const About = lazy(() => import("../components/home/About"));
const Registration = lazy(() => import("../components/registration"));
const SmartSearch = lazy(() => import("../components/smartSearch"));
const ContactUs = lazy(() => import("../components/home/ContactUs"));
const Login = lazy(() => import("../components/auth/Login"));
const NotFound = lazy(() => import("../components/commonComponents/404"));

const RoutesList = () => {
  return (
    <Routes>
      {/* Home page */}
      <Route exact path={"/"} element={<Home />} />
      {/* About page */}
      <Route exact path={"/about"} element={<About />} />
      {/* Registration page */}
      <Route exact path={"/registration"} element={<Registration />} />
      {/* Smart Search page */}
      <Route path={"/"} element={<PrivateRoute />}>
        <Route path={"/smartSearch"} element={<SmartSearch />} />
      </Route>
      <Route path={"/details"} element={<Details />} />
      {/* Contact page */}
      <Route exact path={"/contact-us"} element={<ContactUs />} />
      {/* login page */}
      <Route exact path={"/login"} element={<Login />} />
      {/* not found page */}
      <Route exact path={"*"} element={<NotFound />} />
    </Routes>
  );
};

export default RoutesList;
