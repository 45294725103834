import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEnvelope,
  faLocationDot,
  faPaperPlane,
  faPhone,
  faPhoneVolume,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import classNames from "../commonComponents/utils";
import homeLogo from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

const Header = () => {
  const [sidePanel, setSidePanel] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (offset > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={classNames(
          isSticky ? "fixed top-0 w-full z-10 bg-white" : "",
          "flex items-center justify-between min-[1200px]:px-24 px-4 py-2 shadow"
        )}
      >
        <div className={"flex items-center"}>
          <Link to={"/"}>
            <img src={homeLogo} alt={"Akarma"} width={"250"} />
          </Link>
          <button
            type={"button"}
            className={"grid gap-1 grid-cols-3 ml-12 nine-span-btn"}
            onClick={() => setSidePanel(!sidePanel)}
          >
            <span className="icon-grid fs-2" />
          </button>
        </div>

        <div className={"flex items-center"}>
          <ul className={"min-[1200px]:flex hidden items-center mr-5"}>
            <li>
              <Link
                to={"/"}
                className={classNames(
                  location.pathname === "/"
                    ? "text-cleenheartsSecondary"
                    : "text-cleenheartsBase hover:text-cleenheartsSecondary",
                  "font-bold"
                )}
              >
                Home
              </Link>
            </li>
            <li>
              <a
                href={"/#about"}
                className={classNames(
                  location.pathname === "/#about"
                    ? "text-cleenheartsSecondary"
                    : "text-cleenheartsBase hover:text-cleenheartsSecondary",
                  "font-bold ml-10"
                )}
              >
                About Akarma
              </a>
            </li>
            <li>
              <Link
                to={"/registration"}
                className={classNames(
                  location.pathname === "/registration"
                    ? "text-cleenheartsSecondary"
                    : "text-cleenheartsBase hover:text-cleenheartsSecondary",
                  "font-bold ml-10"
                )}
              >
                Registration
              </Link>
            </li>
            <li>
              <Link
                to={"/login"}
                className={classNames(
                  location.pathname === "/login"
                    ? "text-cleenheartsSecondary"
                    : "text-cleenheartsBase hover:text-cleenheartsSecondary",
                  "font-bold ml-10"
                )}
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                to={"/smartSearch"}
                className={classNames(
                  location.pathname === "/smartSearch"
                    ? "text-cleenheartsSecondary"
                    : "text-cleenheartsBase hover:text-cleenheartsSecondary",
                  "font-bold ml-10"
                )}
              >
                Discover NGOs
              </Link>
            </li>
            <li>
              <Link
                to={"/contact-us"}
                className={classNames(
                  location.pathname === "/contact-us"
                    ? "text-cleenheartsSecondary"
                    : "text-cleenheartsBase hover:text-cleenheartsSecondary",
                  "font-bold ml-10"
                )}
              >
                Contact Us
              </Link>
            </li>
          </ul>

          <div className={"min-[1200px]:hidden flex cursor-pointer"}>
            <FontAwesomeIcon
              icon={faBars}
              size={"xl"}
              onClick={() => setMobileMenu(!mobileMenu)}
            />
          </div>
          <div className="max-[1299px]:hidden">
            <Link to={"/contact-us"} className="cleenhearts-btn">
              <div className="cleenhearts-btn__icon-box">
                <div className="cleenhearts-btn__icon-box__inner">
                  <span class="icon-donate" />
                </div>
              </div>
              <span className="cleenhearts-btn__text">
                Join Akarma Community
              </span>
            </Link>
          </div>
        </div>
      </div>

      {sidePanel && (
        <div className={classNames(sidePanel ? "active" : "", "sidebar-one")}>
          <div
            className={
              "w-full h-full absolute top-0 left-0 right0 bottom-0 bg-cleenheartsDark opacity-70 cursor-[url(./components/assets/images/close.png),_pointer]"
            }
            onClick={() => setSidePanel(false)}
          />

          <div className={"sidebar-one__content"}>
            <div className={"flex items-center justify-between mb-10"}>
              <Link to={"/"}>
                <img src={logoLight} width={"155px"} alt={""} />
              </Link>
              <div className={"absolute right-7 top-6 cursor-pointer"}>
                <FontAwesomeIcon
                  icon={faXmark}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                  onClick={() => setSidePanel(false)}
                />
              </div>
            </div>

            <p className={"mb-10 text-cleenheartsWhite2 capitalize"}>
              Wimply dummy text of the priatype industry orem Ipsum has Maecenas
              quis eros at ante
            </p>

            <div className={"mb-10"}>
              <h4 className={"text-xl text-white mb-4 font-bold"}>
                Information
              </h4>
              <ul>
                <li className={"flex items-center mb-3"}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size={"lg"}
                    className={"text-cleenheartsSecondary mr-2"}
                  />
                  <address className={"mb-0"}>
                    <p className={"text-cleenheartsWhite2 text-lg"}>
                      Ahmedabad Gujarat India
                    </p>
                  </address>
                </li>
                <li className={"flex items-center mb-3"}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size={"lg"}
                    className={"text-cleenheartsSecondary mr-2"}
                  />
                  <div className={"relative"}>
                    <a
                      href={"mailto:contact@akarma.in"}
                      className={
                        "text-cleenheartsWhite2 text-lg hover:text-cleenheartsSecondary hover-underline-animation"
                      }
                    >
                      contact@akarma.in
                    </a>
                  </div>
                </li>
                <li className={"flex items-center mb-3"}>
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    size={"lg"}
                    className={"text-cleenheartsSecondary mr-2"}
                  />
                  <div className={"relative"}>
                    <a
                      href={"tel:(406)555-0120"}
                      className={
                        "text-cleenheartsWhite2 text-lg hover:text-cleenheartsSecondary hover-underline-animation"
                      }
                    >
                      (406) 555-0120
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className={"flex items-center mb-10"}>
              <a href={"https://facebook.com"} className={"mr-3"}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Facebook </span>
              </a>
              <div className={"border-r border-r-Charcoal h-4 w-0.5"} />

              <a href={"https://twitter.com"} className={"mx-3"}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Twitter </span>
              </a>
              <div className={"border-r border-r-Charcoal h-4 w-0.5"} />

              <a
                href={"https://linkedin.com"}
                aria-hidden="true"
                className={"mx-3"}
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Linkedin </span>
              </a>
              <div className={"border-r border-r-Charcoal h-4 w-0.5"} />

              <a
                href={"https://youtube.com"}
                aria-hidden="true"
                className={"ml-3"}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Youtube </span>
              </a>
            </div>

            <div className={""}>
              <label
                className={"text-white mb-4 text-xl"}
                for={"sidebar-email"}
              >
                Newsletter Subscribe
              </label>
              <form
                action={"#"}
                className={"flex items-center"}
                data-url={"MAILCHIMP_FORM_URL"}
              >
                <input
                  type={"email"}
                  name={"sidebar-email"}
                  id={"sidebar-email"}
                  className={
                    "border-none outline-none text-white h-[50px] px-3 bg-white/5 flex justify-center items-center"
                  }
                  placeholder={"Email Address"}
                />
                <button
                  type={"submit"}
                  className={
                    "cursor-pointer h-[50px] w-[50px] bg-cleenheartsSecondary"
                  }
                >
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    size={"lg"}
                    className={"text-cleenheartsBase"}
                  />
                </button>
              </form>
              <div className={"mc-form__response"}></div>
            </div>
          </div>
        </div>
      )}

      {mobileMenu && (
        <div
          className={classNames(
            mobileMenu ? "expanded min-[1200px]:hidden flex" : "hidden",
            "mobile-nav__wrapper"
          )}
        >
          <div
            className={
              "absolute top-0 right-0 left-0 bottom-0 bg-cleenheartsDark opacity-70 cursor-[url(./components/assets/images/close.png),_pointer]"
            }
            onClick={() => setMobileMenu(false)}
          />
          <div className={"mobile-nav__content"}>
            <div className={"flex items-center justify-between mb-10"}>
              <Link to={"/"}>
                <img src={logoLight} width={"155px"} alt={""} />
              </Link>
              <div className={"absolute right-3 top-6 cursor-pointer"}>
                <FontAwesomeIcon
                  icon={faXmark}
                  size={"lg"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                  onClick={() => setMobileMenu(false)}
                />
              </div>
            </div>

            <ul>
              <li
                className={classNames(
                  location.pathname === "/"
                    ? "text-cleenheartsSecondary"
                    : "text-white hover:text-cleenheartsSecondary",
                  "font-bold my-3"
                )}
              >
                <Link to={"/"}> Home </Link>
              </li>
              <hr className={"m-0 border-t-white opacity-10"} />

              <li
                className={classNames(
                  location.pathname === "/about"
                    ? "text-cleenheartsSecondary"
                    : "text-white hover:text-cleenheartsSecondary",
                  "font-bold my-3"
                )}
              >
                <Link to={"/about"}> About Akarma </Link>
              </li>
              <hr className={"m-0 border-t-white opacity-10"} />

              <li
                className={classNames(
                  location.pathname === "/registration"
                    ? "text-cleenheartsSecondary"
                    : "text-white hover:text-cleenheartsSecondary",
                  "font-bold my-3"
                )}
              >
                <Link to={"/registration"}>Registration</Link>
              </li>
              <hr className={"m-0 border-t-white opacity-10"} />

              <li
                className={classNames(
                  location.pathname === "/smartSearch"
                    ? "text-cleenheartsSecondary"
                    : "text-white hover:text-cleenheartsSecondary",
                  "font-bold my-3"
                )}
              >
                <Link to={"/smartSearch"}>NGO Smart Search</Link>
              </li>
              <hr className={"m-0 border-t-white opacity-10"} />

              <li
                className={classNames(
                  location.pathname === "/contact-us"
                    ? "text-cleenheartsSecondary"
                    : "text-white hover:text-cleenheartsSecondary",
                  "font-bold my-3"
                )}
              >
                <Link to={"/contact-us"}>Contact Us</Link>
              </li>
            </ul>

            <ul className={"my-5"}>
              <li className={"flex items-center"}>
                <div
                  className={
                    "bg-cleenheartsSecondary w-[30px] h-[30px] rounded-[50%] mr-2 flex items-center justify-center"
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={"text-cleenheartsBase"}
                    size={"sm"}
                  />
                </div>
                <a
                  href={"mailto:needhelp@cleenhearts.com"}
                  className={"text-white"}
                >
                  needhelp@cleenhearts.com
                </a>
              </li>

              <li className={"flex items-center mt-3"}>
                <div
                  className={
                    "bg-cleenheartsSecondary w-[30px] h-[30px] rounded-[50%] mr-2 flex items-center justify-center"
                  }
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={"text-cleenheartsBase"}
                    size={"sm"}
                  />
                </div>
                <a href={"tel:(406)555-0120"} className={"text-white"}>
                  (406) 555-0120
                </a>
              </li>
            </ul>

            <div className={"flex items-center"}>
              <a href={"https://facebook.com"} className={"mr-1.5"}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size={"1x"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Facebook </span>
              </a>

              <a href={"https://twitter.com"} className={"mx-1.5"}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size={"1x"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Twitter </span>
              </a>

              <a
                href={"https://linkedin.com"}
                aria-hidden={"true"}
                className={"mx-1.5"}
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size={"1x"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Linkedin </span>
              </a>

              <a
                href={"https://youtube.com"}
                aria-hidden={"true"}
                className={"ml-1.5"}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  size={"1x"}
                  className={"text-white hover:!text-cleenheartsSecondary"}
                />
                <span className={"sr-only"}> Youtube </span>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
