import React from "react";
import { Navigate } from "react-router-dom";
import SmartSearch from "../components/smartSearch";

const PrivateRoute = () => {
  const auth_token = localStorage.getItem("access_token");
  return auth_token ? <SmartSearch /> : <Navigate to={"/login"} />;
};

export default PrivateRoute;
